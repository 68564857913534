import VueRouter from "vue-router";
import { v1 } from "uuid";
import { getValidateCodeUrl, ValidateCodeScene } from "@/pages/auth/api";
import Storage from "@/utils/storage";
import { getCurrentMainDomain } from "@/utils/domain";

// 新增套餐卡商城2。0 combo-card-shop-two
export type AuthRoleKeys =
  | "cash-shop"
  | "combo-card-shop"
  | "supplier"
  | "enterprise"
  | "combo-card-shop-two"
  | "double-selection"
  | "combo-card-shop-minxin"
  | "oneshopping"
  | "eownsupplier";
/**
 * 判断是否是测试环境
 */
// function detectIsDebug() {
//   if (location.host.includes('test')) return true;
//   if (location.host.includes('localhost')) return true;
//   if (location.host.includes('172')) return true;
//   if (location.host.includes('192')) return true;
//   return false;
// }

function getRoute() {
  const { app } = window as any;
  return app.$route;
}

function getRouter() {
  const { app } = window as any;
  return app.$router as VueRouter;
}

function getStore() {
  const { app } = window as any;
  return app.$store;
}

export class AuthRole {
  private accounts!: { value: string; pwd: string }[];
  uuid = "";

  private get storeKey() {
    return `role_${this.key}`;
  }

  private get uuidStoreKey() {
    return `role_${this.key}_uuid`;
  }

  constructor(
    public key: AuthRoleKeys,
    public title: string,
    public code: number
  ) {
    this.loadAccounts();

    this.uuid =
      localStorage.getItem(this.uuidStoreKey) ||
      (() => {
        const uuid = v1({ msecs: Date.now() * Math.random() });
        localStorage.setItem(this.uuidStoreKey, uuid);
        return uuid;
      })();
  }

  getCodeImageUrl(key: ValidateCodeScene) {
    return getValidateCodeUrl(key, this.uuid);
  }

  loadAccounts() {
    const value = localStorage.getItem(this.storeKey);
    if (value) {
      this.accounts = JSON.parse(value);
    } else {
      this.accounts = [];
    }
  }

  saveAccounts() {
    localStorage.setItem(this.storeKey, JSON.stringify(this.accounts));
  }

  searchAccount = (queryString: string, callback: (...args) => any) => {
    let accounts = [...this.accounts];

    if (queryString) {
      accounts = accounts.filter((account) =>
        account.value.includes(queryString)
      );
    }

    callback(accounts);
  };

  removeAccount = (value: string) => {
    this.accounts = this.accounts.filter((account) => account.value !== value);
    this.saveAccounts();
  };

  unshiftAccount(username: string, pwd: string) {
    this.accounts = this.accounts.filter(
      (account) => account.value !== username
    );
    this.accounts.unshift({
      value: username,
      pwd,
    });
    this.saveAccounts();
  }

  login(username: string, password: string, captcha: string) {
    return new Promise<any>((resolve, reject) => {
      getStore()
        .dispatch("loginAction", {
          member_type: this.code,
          uuid: this.uuid,
          username,
          password,
          captcha,
        })
        .then(() => {
          this.unshiftAccount(username, password);

          const { forward } = getRoute().query;

          getRouter()
            .replace({ path: forward || "/" })
            .then(() => {
              resolve(true);
            });
        }, reject);
    });
  }

  loginDropShipping(mobile: string, sms_code: string, captcha: string) {
    return new Promise<any>((resolve, reject) => {
      const obj: any = {
        member_type: this.code,
        uuid: this.uuid,
        mobile,
        sms_code,
      };
      if (captcha) obj.captcha = captcha;
      getStore()
        .dispatch("loginDropShippingAction", obj)
        .then(async (response) => {
          // this.unshiftAccount(mobile, sms_code);
          const { forward } = getRoute().query;
          getRouter()
            .replace({ path: forward || "/" })
            .then(() => {
              resolve(true);
            });
        }, reject);
    });
  }

  smscodeDropShipping(mobile: string, captcha: string) {
    return new Promise<any>((resolve, reject) => {
      getStore()
        .dispatch("smscodeDropShippingAction", {
          member_type: this.code,
          uuid: this.uuid,
          mobile,
          captcha,
        })
        .then(resolve, reject);
    });
  }
}

export class AuthService {
  roles: { [key: string]: AuthRole } = {};
  currentRole: AuthRole = null!;
  urlList: any = [
    "https://testtwosupplier.sshlqf.com", // 供应商开发环境
    "https://testsupplier.sshlqf.com", // 供应商测试环境
    "https://supplier.sshlqf.com", // 供应商正式环境
    "https://testtwogroup.sshlqf.com", // 企业端开发环境
    "https://testgroup.sshlqf.com", // 企业端测试环境
    "https://group.sshlqf.com", // 企业端正式环境
    "https://testtwocompany.sshlqf.com", // 现金商城开发环境
    "https://testcompany.sshlqf.com", // 现金商城测试环境
    "https://company.sshlqf.com", // 现金商城正式环境
    "https://testtwocards.sshlqf.com", // 套餐卡商城开发环境
    "https://testcards.sshlqf.com", // 套餐卡商城测试环境
    "https://cards.sshlqf.com", // 套餐卡商城正式环境
    "https://testtwoaicards.sshlqf.com", // AI智能套餐卡开发环境
    "https://testaicards.sshlqf.com", // AI智能套餐卡测试环境
    "https://aicards.sshlqf.com", // AI智能套餐卡正式环境
    "https://testtwosxdouble.sshlqf.com", // 双选开发环境
    "https://testsxdouble.sshlqf.com", // 双选测试环境
    "https://sxdouble.sshlqf.com", // 双选正式环境
    "https://testtwojccards.sshlqf.com", // 小程序商城开发环境
    "https://testjccards.sshlqf.com", // 小程序商城测试环境
    "https://jccards.sshlqf.com", // 小程序商城正式环境
    "https://testtwooneshopping.sshlqf.com", // 一件代发商城开发环境
    "https://testoneshopping.sshlqf.com", // 一件代发商城测试环境
    "https://oneshopping.sshlqf.com", // 一件代发商城正式环境
    "https://testtwoeownsupplier.sshlqf.com", // 企业自有供应商开发环境
    "https://testeownsupplier.sshlqf.com", // 企业自有供应商测试环境
    "https://eownsupplier.sshlqf.com", // 企业自有供应商正式环境
  ];

  form = {
    username: "",
    pwd: "",
    code: "",
    rules: {
      username: [
        {
          required: true,
          message: "请输入登录账户！",
        },
      ],
      pwd: [
        {
          required: true,
          message: "请输入登录密码！",
        },
      ],
      code: [
        {
          required: true,
          message: "请输入验证码！",
        },
      ],
    },
  };

  mobileForm = {
    mobile: "",
    sms_code: "",
    captcha: "",
    rules: {
      mobile: [
        {
          required: true,
          message: "请输入登录手机号！",
        },
        {
          pattern: /^1[3456789]\d{9}$/,
          message: "请输入正确格式的登录手机号！",
        },
      ],
      sms_code: [
        {
          required: true,
          message: "请输入验证码！",
        },
      ],
      captcha: [
        {
          required: true,
          message: "请输入图形验证码！",
        },
      ],
    },
  };

  constructor() {
    this.appendRole(new AuthRole("cash-shop", "现金商城登录", 2));
    this.appendRole(new AuthRole("combo-card-shop", "套餐卡商城登录", 5));
    this.appendRole(new AuthRole("supplier", "供应商登录", 4));
    this.appendRole(new AuthRole("enterprise", "企业登录", 3));
    // 套餐卡2。0
    this.appendRole(new AuthRole("combo-card-shop-two", "智能套餐卡登录", 6));
    // 双选商城
    this.appendRole(new AuthRole("double-selection", "双选商城登录", 7));
    // 智能计次卡
    this.appendRole(
      new AuthRole("combo-card-shop-minxin", "小程序商城登录", 8)
    );
    // 一件代发商城
    this.appendRole(new AuthRole("oneshopping", "一件代发商城登录", 9));

    this.appendRole(new AuthRole("eownsupplier", "自有供应商登录", 10));
    const user = Storage.getItem("seller_user") as any;

    if (user) {
      switch (user.shop_type) {
        case 1:
          this.switchRole("cash-shop");
          break;
        case 2:
          this.switchRole("supplier");
          break;
        case 3:
          this.switchRole("enterprise");
          break;
        case 6:
          this.switchRole("combo-card-shop-two");
          break;
        case 7:
          this.switchRole("double-selection");
          break;
        case 8:
          this.switchRole("combo-card-shop-minxin");
          break;
        case 9:
          this.switchRole("oneshopping");
          break;
        default:
          this.switchRole("combo-card-shop");
          break;
      }
    }
  }

  buildRole() {
    let type;
    if (/^[0-9].*[0-9]$/.test(location.host)) {
      type = getRoute().query.type || "enterprise";
      if (location.port === "6820") {
        type = "oneshopping";
      }
    } else {
      if (location.host.includes("supplier")) {
        type = "supplier";
      } else if (location.host.includes("group")) {
        type = "enterprise";
      } else if (location.host.includes("company")) {
        type = "cash-shop";
      } else if (location.host.includes("aicards")) {
        type = "combo-card-shop-two";
      } else if (location.host.includes("jccards")) {
        type = "combo-card-shop-minxin";
      } else if (location.host.includes("cards")) {
        type = "combo-card-shop";
      } else if (location.host.includes("sxdouble")) {
        type = "double-selection";
      } else if (location.host.includes("oneshopping")) {
        type = "oneshopping";
      }
    }
    return this.switchRole(type);
  }

  appendRole(role: AuthRole) {
    this.roles[role.key] = role;
  }

  /** 获取当前地址栏主域名替换 */
  getLocationUrl(dataUrl) {
    const endUrl = getCurrentMainDomain(); // 获取当前地址栏后缀主域名地址

    const newUrlList = dataUrl.split(".");
    const startUrl = dataUrl.split(
      newUrlList[newUrlList.length - 2] +
        "." +
        newUrlList[newUrlList.length - 1]
    )[0]; // 获取当前地址前缀去掉主域名地址
    const newUrl = startUrl + endUrl;
    return newUrl;
  }

  changeRouter(type) {
    switch (type) {
      case "supplier": // 供应商
        if (location.host.includes("testtwo")) {
          return this.getLocationUrl(this.urlList[0]);
        } else if (location.host.includes("test")) {
          return this.getLocationUrl(this.urlList[1]);
        } else {
          return this.getLocationUrl(this.urlList[2]);
        }
      case "enterprise": // 企业端
        if (location.host.includes("testtwo")) {
          return this.getLocationUrl(this.urlList[3]);
        } else if (location.host.includes("test")) {
          return this.getLocationUrl(this.urlList[4]);
        } else {
          return this.getLocationUrl(this.urlList[5]);
        }
      case "cash-shop": // 现金商城
        if (location.host.includes("testtwo")) {
          return this.getLocationUrl(this.urlList[6]);
        } else if (location.host.includes("test")) {
          return this.getLocationUrl(this.urlList[7]);
        } else {
          return this.getLocationUrl(this.urlList[8]);
        }
      case "combo-card-shop": // 套餐卡商城
        if (location.host.includes("testtwo")) {
          return this.getLocationUrl(this.urlList[9]);
        } else if (location.host.includes("test")) {
          return this.getLocationUrl(this.urlList[10]);
        } else {
          return this.getLocationUrl(this.urlList[11]);
        }
      case "combo-card-shop-two": // AI智能套餐卡商城
        if (location.host.includes("testtwo")) {
          return this.getLocationUrl(this.urlList[12]);
        } else if (location.host.includes("test")) {
          return this.getLocationUrl(this.urlList[13]);
        } else {
          return this.getLocationUrl(this.urlList[14]);
        }
      case "double-selection": // 双选商城
        if (location.host.includes("testtwo")) {
          return this.getLocationUrl(this.urlList[15]);
        } else if (location.host.includes("test")) {
          return this.getLocationUrl(this.urlList[16]);
        } else {
          return this.getLocationUrl(this.urlList[17]);
        }
      case "combo-card-shop-minxin": // 小程序商城
        if (location.host.includes("testtwo")) {
          return this.getLocationUrl(this.urlList[18]);
        } else if (location.host.includes("test")) {
          return this.getLocationUrl(this.urlList[19]);
        } else {
          return this.getLocationUrl(this.urlList[20]);
        }
      case "oneshopping":
        if (location.host.includes("testtwo")) {
          return this.getLocationUrl(this.urlList[21]);
        } else if (location.host.includes("test")) {
          return this.getLocationUrl(this.urlList[22]);
        } else {
          return this.getLocationUrl(this.urlList[23]);
        }
      case "eownsupplier":
        if (location.host.includes("testtwo")) {
          return this.getLocationUrl(this.urlList[24]);
        } else if (location.host.includes("test")) {
          return this.getLocationUrl(this.urlList[25]);
        } else {
          return this.getLocationUrl(this.urlList[26]);
        }
    }
  }

  hrefChange(type) {
    const isEownSupplier = location.hostname.includes("eownsupplier");
    if (this.currentRole) {
      if (type !== this.currentRole.key) {
        this.currentRole = this.roles[type];
        location.href = this.changeRouter(type) as string;
      }
    } else {
      this.currentRole = this.roles[type];
    }
    if (isEownSupplier) this.currentRole = this.roles["eownsupplier"];
    return this.currentRole;
  }

  switchRole(type: AuthRoleKeys) {
    const isEownSupplier = location.hostname.includes("eownsupplier");
    if (isEownSupplier) {
      this.currentRole = this.roles["eownsupplier"];
      return this.currentRole;
    }
    if (/^[0-9].*[0-9]$/.test(location.host)) {
      if (this.currentRole) {
        if (type !== this.currentRole.key) {
          this.currentRole = this.roles[type];
          if (type === "combo-card-shop-two") {
            location.href = `${location.protocol}//${location.hostname}:6605/login?type=combo-card-shop-two`;
          } else if (type === "combo-card-shop-minxin") {
            location.href = `${location.protocol}//${location.hostname}:6605/login?type=combo-card-shop-minxin`;
          } else if (type === "double-selection") {
            location.href = `${location.protocol}//${location.hostname}:6819/login?type=double-selection`;
          } else if (type === "oneshopping") {
            location.href = `${location.protocol}//${location.hostname}:6820/login?type=oneshopping`;
          } else {
            location.href = `${location.protocol}//${location.hostname}:6603/login?type=${type}`;
          }
          // getRouter().replace({query: {type}});
        }
      } else {
        this.currentRole = this.roles[type];
      }
      return this.currentRole;
    } else {
      return this.hrefChange(type);
    }
  }

  selectAccount(account: string, pwd: string) {
    this.form.username = account;
    this.form.pwd = pwd;
  }

  trimForm() {
    this.form.username = this.form.username.trim();
    this.form.pwd = this.form.pwd.trim();
    this.form.code = this.form.code.trim();
  }

  clearForm() {
    this.form.username = "";
    this.form.pwd = "";
    this.form.code = "";
  }

  login() {
    this.trimForm();
    return this.currentRole.login(
      this.form.username,
      this.form.pwd,
      this.form.code
    );
  }

  trimMobileForm() {
    const arr = ["mobile", "sms_code", "captcha"];
    arr.forEach(
      (item) => (this.mobileForm[item] = this.mobileForm[item].trim())
    );
  }

  clearMobileForm() {
    const arr = ["mobile", "sms_code", "captcha"];
    arr.forEach((item) => (this.mobileForm[item] = ""));
  }

  loginDropShipping() {
    this.trimMobileForm();
    return this.currentRole.loginDropShipping(
      this.mobileForm.mobile,
      this.mobileForm.sms_code,
      this.mobileForm.captcha
    );
  }

  smscodeDropShipping(mobile, captcha) {
    return this.currentRole.smscodeDropShipping(mobile, captcha);
  }
}

export const $auth = new AuthService();
